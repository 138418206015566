import React, { Component } from "react";

import { Divider, Layout, Collapse, Card } from "antd";
const { Meta } = Card;
class ProgramContacts extends Component {
  state = {};
  render() {
    return (
      <>
        <Divider />

        <Layout style={{ padding: 25, background: "#fff" }}>
          <Collapse
            bordered={false}
            defaultActiveKey={["detail-view"]}
            className="detail-view"
          >
            <Collapse.Panel
              header={<h2>Program Contacts</h2>}
              key="detail-view"
            >
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                <Card
                  hoverable
                  style={{ width: 240, margin: "10px" }}
                  cover={
                    <img
                      style={{ border: "black 2px solid" }}
                      alt="example"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcThtriGIMd9-W9rQ2q7Kfw3Cjz8U9PougOyw0YH6_NR_RjCrNgv"
                    />
                  }
                >
                  <Meta title="Professor X" description="Senior lecturer" />
                </Card>
                <Card
                  hoverable
                  style={{ width: 240, margin: "10px" }}
                  cover={
                    <img
                      style={{ border: "black 2px solid" }}
                      alt="example"
                      src="https://png.pngtree.com/png-vector/20191116/ourlarge/pngtree-business-office-girl-avatar-icon-vector-download-png-image_1991055.jpg"
                    />
                  }
                >
                  <Meta title="Professor Y" description="Senior lecturer" />
                </Card>
                <Card
                  hoverable
                  style={{ width: 240, margin: "10px" }}
                  cover={
                    <img
                      style={{ border: "black 2px solid" }}
                      alt="example"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcThtriGIMd9-W9rQ2q7Kfw3Cjz8U9PougOyw0YH6_NR_RjCrNgv"
                    />
                  }
                >
                  <Meta title="Dr. Z" description="Lecturer" />
                </Card>
                <Card
                  hoverable
                  style={{ width: 240, margin: "10px" }}
                  cover={
                    <img
                      style={{ border: "black 2px solid" }}
                      alt="example"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQsDrfjX-4hAtcz_KJN23tjCP6UhCmTxNQy68onFxCOndvXm_Jd"
                    />
                  }
                >
                  <Meta title="ABCDXYZ" description="Tutor" />
                </Card>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Layout>
      </>
    );
  }
}

export default ProgramContacts;

import React, { Component } from "react";
import { List, Avatar, Row, Col } from "antd";
import Calendar from "react-calendar";
class Deadline extends Component {
  state = {};
  render() {
    const data = [
      {
        title: "Ant Design Title 1"
      },
      {
        title: "Ant Design Title 2"
      },
      {
        title: "Ant Design Title 3"
      },
      {
        title: "Ant Design Title 4"
      },
      {
        title: "Ant Design Title 2"
      },
      {
        title: "Ant Design Title 3"
      },
      {
        title: "Ant Design Title 4"
      }
    ];
    return (
      <Row type="flex" gutter={16} style={{ padding: "10px" }}>
        <Col span={24}>
          <div>
            <span style={{ fontWeight: "bold" }}>Deadlines</span>
          </div>
          <div>
            <List
              style={{
                background: "rgba(129, 87, 255,0.5)",
                height: "20vh",
                overflowY: "scroll",
                marginTop: "10px"
              }}
              itemLayout="horizontal"
              dataSource={data}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    }
                    title={<a href="https://ant.design">{item.title}</a>}
                    description="Ant Design, a design language for background "
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default Deadline;

import React, { Component } from "react";

import { Row, Col } from "antd";
import SummaryTile from "./SummaryTile";

class Summary extends Component {
  state = {};
  render() {
    const numRequiredCourses = this.props.stages
      .map(stage => stage.required_num_of_courses)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const allCourses = this.props.stages
      .map(stage => [...stage.courses])
      .flat(1);

    const completed = allCourses.filter(course => course.status === "COMPLETED")
      .length;
    const inProgress = allCourses.filter(course => course.status === "ENROLLED")
      .length;
    const future = numRequiredCourses - completed - inProgress;

    console.log(allCourses);

    //const  future=
    return (
      <div>
        <Row gutter={16} type="flex" justify="space-around">
          <SummaryTile title={"Future"} value={future} />
          <SummaryTile title={"In Progress"} value={inProgress} />
          <SummaryTile title={"Completed"} value={completed} />
          <SummaryTile title={"Placeholder"} value={3} />
        </Row>
      </div>
    );
  }
}

export default Summary;

import React, { Component, Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import { Button, Layout, Collapse, Tabs, Row, Col } from "antd";
import axios from "axios";

import preval from "preval.macro";

import Summary from "./programSummary/Summary";
import NotificationPanel from "./NotificationPanel";

// import details from "../../data/details";
// import user from "../../data/user";
// import tasks from "../../data/tasks";

// import Greeting from "./UserGreeting";

// import CourseDetailsView from "./CourseDetailsView";

import AppContext from "../../AppContext";
import CourseDetailsView from "./CourseDetailsView";
import ProgramContacts from "./contacts/ProgramContacts";
import Deadline from "./deadlines/Deadline";

const ProgramTracker = lazy(() => import("./tracker/ProgramTracker"));

class Dashboard extends Component {
  static contextType = AppContext;

  state = { enrollments: [], stages: [], terms: [], allCourses: [] };

  componentDidMount = async () => {
    const stagesResponse = await axios.get("admin/stage/detail/");

    const selectedCourse = stagesResponse.data
      .map(stage => [...stage.courses])
      .flat([1])
      .find(course => course.moodle_id === parseInt(this.context.context_id));

    const selected = selectedCourse ? selectedCourse.id : null;

    this.setState({
      selected,

      stages: stagesResponse.data
    });
  };

  render() {
    const { selected, stages } = this.state;
    const { logout } = this.context;

    const allCourses = stages.map(stage => [...stage.courses]).flat([1]);

    return (
      <Layout style={{ minHeight: "100%" }}>
        <Layout.Header
          style={{
            display: "flex",
            alignItems: "center",
            background: "#94107E"
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            <NavLink to="/" style={{ height: "100%" }}>
              <img
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW logo"
                style={{
                  maxHeight: "100%",
                  width: "auto",
                  padding: "10px 0",
                  marginRight: 25
                }}
              />
            </NavLink>

            <h1
              style={{
                fontFamily: "Sommet",
                color: "white",
                margin: 0,
                lineHeight: 1
              }}
            >
              Practical Legal Training
            </h1>
          </div>

          <Button icon="logout" onClick={logout}>
            Log out
          </Button>
        </Layout.Header>

        <Layout.Content
          style={{
            padding: 25,
            background:
              "url(https://cdn.teaching.unsw.edu.au/PLT/program_tracker_background.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col xs={24} md={10} lg={10}>
              <Suspense fallback={<div>Loading...</div>}>
                <ProgramTracker
                  stages={this.state.stages}
                  onSelect={id => this.setState({ selected: id })}
                />
              </Suspense>
            </Col>
            <Col xs={24} md={14} lg={14}>
              <Row>
                <Col xs={24} md={24} lg={24} xl={24} xxl={0}>
                  {selected && (
                    <CourseDetailsView
                      course={allCourses.find(course => course.id === selected)}
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={24} lg={24} xl={16}>
                  <Row>
                    <Summary stages={stages} />
                  </Row>
                  <Row>
                    <Deadline stages={stages} />
                  </Row>
                </Col>

                <Col xs={24} md={24} lg={24} xl={8}>
                  <NotificationPanel />
                </Col>
              </Row>
              <Row>
                <Col xs={0} md={0} lg={0} xl={0} xxl={24}>
                  {selected && (
                    <CourseDetailsView
                      course={allCourses.find(course => course.id === selected)}
                    />
                  )}
                </Col>
                <Col xs={24} md={23} lg={23} xl={24} xxl={24}>
                  <ProgramContacts
                    course={allCourses.find(course => course.id === selected)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Greeting name={this.context.firstName} />
          <Layout style={{ padding: 25, background: "#fff" }}>
            <Collapse bordered={false} defaultActiveKey={["courses"]}>
              <Collapse.Panel header={<h2>Your courses</h2>} key="courses">
                <Tabs type="card">
                  <Tabs.TabPane
                    tab={`Currently enrolled (${currentEnrollments.length})`}
                    key="enrolled"
                  >
                    <div
                      style={{ flexWrap: "wrap", display: "flex", padding: 8 }}
                    >
                      {currentEnrollments.map(enrollment => (
                        <CourseCard
                          key={enrollment.id}
                          course={enrollment.course}
                          onSelect={() =>
                            this.setState({ selected: enrollment.course.id })
                          }
                          isSelected={selected === enrollment.course.id}
                        />
                      ))}
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={`Future courses (${futureEnrollments.length})`}
                    key="future"
                  >
                    <div
                      style={{ flexWrap: "wrap", display: "flex", padding: 8 }}
                    >
                      {futureEnrollments.map(enrollment => (
                        <CourseCard
                          key={enrollment.id}
                          course={enrollment.course}
                          onSelect={() =>
                            this.setState({ selected: enrollment.course.id })
                          }
                          isSelected={selected === enrollment.course.id}
                          status={enrollment.status}
                        />
                      ))}
                    </div>
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={`Completed courses (${pastEnrollments.length})`}
                    key="completed"
                  >
                    <div
                      style={{ flexWrap: "wrap", display: "flex", padding: 8 }}
                    >
                      {pastEnrollments.map(enrollment => (
                        <CourseCard
                          key={enrollment.id}
                          course={enrollment.course}
                          onSelect={() =>
                            this.setState({ selected: enrollment.course.id })
                          }
                          isSelected={selected === enrollment.course.id}
                          status={enrollment.status}
                        />
                      ))}
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </Collapse.Panel>
            </Collapse>

            <div style={{ marginTop: 12 }}>
              <Button
                icon="appstore"
                onClick={() => this.setState({ showRoadmap: true })}
              >
                Program roadmap
              </Button>
            </div>
          </Layout>

          {selected && (
            <CourseDetailsView
              course={allCourses.find(course => course.id === selected)}
            />
          )}
       

        <Roadmap
          visible={showRoadmap}
          courses={enrollments}
          stages={stages}
          closeRoadmap={() => this.setState({ showRoadmap: false })}
          onSelect={selected => this.setState({ selected })}
        /> */}
        </Layout.Content>
        <Layout.Footer
          className="footer"
          style={{ background: "rgb(59,16,103)" }}
        >
          <ul>
            <li>
              <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>
            </li>
            <li>
              <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="https://www.unsw.edu.au/copyright-disclaimer">
                Copyright &amp; Disclaimer
              </a>
            </li>
            <li>
              <div>Office of the Pro Vice-Chancellor (Education)</div>
              <div>UNSW Sydney NSW 2052 Australia</div>
              <div>Authorised by: Pro Vice-Chancellor (E), UNSW</div>
              <div style={{ color: "rgba(117, 117, 117, 0.5)" }}>
                {`Build date: ${preval`
                    const moment = require("moment");
                    module.exports = moment().format("DD/MM/YYYY");
                  `}`}
              </div>
            </li>
          </ul>
        </Layout.Footer>
      </Layout>
    );
  }
}

export default Dashboard;

import React, { Component } from "react";
import { List, Avatar } from "antd";

class NotificationPanel extends Component {
  state = {};
  render() {
    const data = [
      {
        title: "Ant Design Title 1"
      },
      {
        title: "Ant Design Title 2"
      },
      {
        title: "Ant Design Title 3"
      },
      {
        title: "Ant Design Title 4"
      }
    ];
    return (
      <div style={{ border: "2px grey solid", padding: "10px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          Notifications
        </div>
        <div
          style={{ background: "rgba(129, 87, 255,0.5)", marginTop: "10px" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={<a href="https://ant.design">{item.title}</a>}
                  description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default NotificationPanel;

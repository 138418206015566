import React from "react";
import { Row, Col } from "antd";

function SummaryTile(props) {
  const { title, value } = props;
  return (
    <Col
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={11}
      style={{ background: "white", margin: "5px", padding: "5px" }}
    >
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{ padding: "20px", fontSize: "25px" }}
      >
        <Col xs={21} md={21} lg={20} xl={16}>
          {title}
        </Col>

        <Col offset={1} xs={2} md={2} lg={3} xl={7}>
          {value}
        </Col>
      </Row>
    </Col>
  );
}

export default SummaryTile;
